.book-wrap{
  flex: 1;
  -webkit-flex: 1;
  padding-bottom: 66px;
  >.hotel-info{
    height: 172px;
    background-color: #EEDFC7;
    padding: 0 16px;
    overflow: hidden;
    position: relative;
    .title{
      font-size: 20px;
      font-weight: 700;
      margin-top: 24px;
      position: relative;
      z-index: 2;
    }
    .date{
      margin-top: 16px;
      background-color: #ffffff;
      border-radius: 2px;
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 2;
      height: 60px;
      padding: 0 16px;
      >.date-item{
        -webkit-display: flex;
        display: flex;
        align-items: center;
        >label{

        }
        >.value{
          //color: #D09632;
          font-size: 20px;
          font-weight: 700;
          margin-left: 10px;
          &.value-empty{
            color: #333333;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      >.night-long{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 35px;
        height: 22px;
        line-height: 22px;
        border-radius: 12px;
        border: 1px solid rgba(51, 51, 51, 0.1);
        text-align: center;
        font-size: 12px;
      }
    }
    .tip{
      margin-top: 12px;
      position: relative;
      z-index: 2;
    }
    >.hotel-info-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.45;
    }
  }
  .block-title{
    font-size: 20px;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 6px;
    position: relative;
  }
  >.form-block{
    padding: 0 24px;
    >.form-item{
      border-bottom: solid 1px #E6E6E6;
      height: 62px;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      font-size: 16px;
      >label{
        margin-right: 24px;
        width: 48px;
      }
      >input{
        background: transparent;
        border: transparent;
        -webkit-flex: 1;
        flex: 1;
      }
    }
  }
  >.coupon-block{
    padding: 0 24px;
    .coupon-area{
      //background-color: rgba(228, 203, 163, 0.1);
      padding: 12px 16px;
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      position: relative;
      &.disabled{
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
        filter: gray;
        filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
      }
      >span{
        width: 80px;
        position: relative;
        z-index: 2;
      }
      >.info{
        -webkit-display: flex;
        display: flex;
        position: relative;
        z-index: 2;
        >span{
          color: rgba(51, 51, 51, 0.45);
        }
        >em{
          width: 9px;
          height: 16px;
          margin-left: 16px;
          margin-top: 3px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
        }
        .coupon-detail{
          -webkit-display: flex;
          display: flex;
          flex-direction: column;
          >span{
            color: #BC8B4C;
            margin-bottom: 8px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
      >.opacity-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.04;
      }
    }
  }
  >.special-block{
    padding: 0 24px;
    margin-bottom: 24px;
    >.block-title{
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >em{
        font-weight: 100;
        width: 11px;
        height: 10px;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        &.active{
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
    .special-content{
      color: rgba(51, 51, 51, 0.65);
      font-size: 12px;
      line-height: 20px;
    }
    .special-living{
      margin-top: 16px;
      >.title{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .special-item{
        height: 34px;
        >span{
          display: inline-block;
          padding: 2px 9px;
          border: solid 1px #E6E6E6;
          border-radius: 2px;
          margin-right: 12px;
          &.active{
            color: #BC8B4C;
            border: solid 1px #BC8B4C;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    .special-else{
      margin-top: 16px;
      >.title{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      >.else-area{
        height: 80px;
        border: solid 1px #E6E6E6;
        border-radius: 2px;
        position: relative;
        -webkit-display: flex;
        display: flex;
        padding: 12px;
        >textarea{
          background: transparent;
          border: transparent;
        }
      }
    }
  }
  >.bottom-block{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
    height: 66px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-display: flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    z-index: 100;
    >.price{
      -webkit-display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      .book-value{
        color: #FF4F3F;
        font-size: 20px;
        font-weight: 700;
        >i{
          font-size: 12px;
          font-weight: 400;
        }
      }
      .coupon-value{
        color: rgba(51, 51, 51, 0.65);
        font-size: 12px;
      }
    }
    >.info-block{
      -webkit-display: flex;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .price-detail{
      color: #BC8B4C;
      font-size: 12px;
      margin-right: 19px;
      >em{
        font-weight: 100;
        width: 10px;
        height: 10px;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        position: relative;
        top: -3px;
        &.active{
          top: 0;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
    .submit-btn{
      border-radius: 100px;
      color: #fff;
      font-size: 18px;
      width: 120px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      font-weight: 700;
      background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
      background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
      background: linear-gradient(left, #E4CBA3, #C3A87C);
    }
  }

  .price-detail-content-mask{
    background-color: rgba(18, 19, 20, 0.5);
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .price-detail-content{
    max-height: 166px;
    background-color: #fff;
    border-bottom: solid 1px #E6E6E6;
    position: fixed;
    bottom: 66px;
    left: 0;
    right: 0;
    overflow: auto;
    padding: 16px;
    z-index: 100;
    >.title{
      font-size: 16px;
      font-weight: 700;
    }
    >.details{
      margin-top: 8px;
      >.detail-item{
        height: 26px;
        -webkit-display: flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >label{
          color: rgba(51, 51, 51, 0.85);
          text-align: left;
        }
        >span{
          color: #121314;
          text-align: right;
        }
      }
    }
  }

  .confirm-book-mask{
    background-color: rgba(18, 19, 20, 0.5);
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .confirm-book{
    background-color: #fff;
    position: fixed;
    z-index: 102;
    left: 24px;
    right: 24px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border-radius: 4px;
    >.btn{
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-top: 1px solid #E6E6E6;
    }
    >.content{
      height: 180px;
      overflow: auto;
      -webkit-display: flex;
      display: flex;
      flex-direction: column;
      padding: 24px;
      .c-item{
        -webkit-display: flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 22px;
        margin-bottom: 16px;
        opacity: 0;
        //transition: opacity 0.25s linear;
        &.ci1{ opacity: 1; transition: opacity 0.25s linear 0.5s; }
        &.ci2{ opacity: 1; transition: opacity 0.25s linear 1s; }
        &.ci3{ opacity: 1; transition: opacity 0.25s linear 1.5s; }
        &.ci4{ opacity: 1; transition: opacity 0.25s linear 2s; }
        &.ci5{ opacity: 1; transition: opacity 0.25s linear 2.5s; }
        &.ci6{ opacity: 1; transition: opacity 0.25s linear 3s; }
        &.ci7{ opacity: 1; transition: opacity 0.25s linear 3.5s; }
        &:last-child{
          margin-bottom: 0;
        }
        >label{
          font-size: 16px;
        }
        >i{
          width: 11px;
          height: 9px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .calendar-wrap-mask{
    background-color: rgba(18, 19, 20, 0.5);
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .calendar-container{
    overflow: hidden;
    position: fixed;
    z-index: 102;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: transform 0.25s;
    transition: transform 0.25s;
    &.calendar-container-out{
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: transform 0.25s;
    }
  }

  .coupons-container{
    overflow: hidden;
    position: fixed;
    z-index: 102;
    left: 0;
    top: 44px;
    right: 0;
    bottom: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform 0.25s;
    transition: transform 0.25s;
    &.coupons-container-out{
      -webkit-transform: translateX(0);
      transform: translateX(0);
      -webkit-transition: transform 0.25s;
    }
  }
}