.page-header{
	height: 44px;
	display: flex;
	align-items: center;
	display: flex;
	flex-direction: row;
	background: #f8f8f8;
	position: fixed;
	width: 100%;
	top: 0;
	text-align: center;
	vertical-align: middle;
	z-index: 801;
	color: #333;
	overflow: hidden;
	border-bottom: solid 1px #dcdcdc;
	padding: 0 16px;
	box-sizing: border-box;
	font-size: 14px;
	.txt{
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.back{
		width: 44px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.icon-sanjiaoxing{
			align-items: flex-end;
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
		}
		&.icon-home{
			justify-content: flex-end;
		}
	}
}
.page-header-sapce{
	height: 44px;
	width: 100%;
}