.page-container {
  display: flex;
  -webkit-display: flex;
  flex-direction: column;
  background-color: #fff;
}
.page-container > header {
  height: 44px;
  line-height: 44px;
  position: relative;
  z-index: 100;
  background-color: #fff;
  display: flex;
  -webkit-display: flex;
  align-items: center;
  padding: 0 18px;
}
.page-container > header > span {
  position: relative;
  z-index: 99;
}
.page-container > header > span.back {
  color: #676767;
  font-size: 16px;
}
.page-container > header > span.title {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #293031;
  font-size: 18px;
}
.page-container > section {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  -webkit-display: flex;
  flex-direction: column;
}
.page-container > .login-out {
  background-color: #ffffff;
  overflow: hidden;
  margin-top: -1px;
  height: 0;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  -webkit-transition: height 0.25s linear 0.25s;
  transition: height 0.25s linear 0.25s;
  border-bottom: solid 1px rgba(220, 220, 220, 0.35);
}
.page-container > .login-out em {
  font-style: normal;
}
.page-container > .login-out span {
  background-color: #ffffff;
  border: solid 1px #ff5f12;
  border-radius: 4px;
  color: #ff5f12;
  padding: 6px 10px;
  margin-left: 10px;
}
.page-container > .login-out.display {
  height: 56px;
}
