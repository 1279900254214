.calendar-wrap{
  height: 92.67%;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 102;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  >.title{
    height: 44px;
    -webkit-display: flex;
    display: flex;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
    >em{
      font-style: normal;
      position: absolute;
      left: 0;
      padding: 0 18px;
      height: 44px;
      line-height: 44px;
    }
  }
  >.date{
    height: 50px;
    background-color: #ffffff;
    -webkit-display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 24px;
    >.date-item{
      -webkit-display: flex;
      display: flex;
      align-items: center;
      >label{

      }
      >.value{
        color: #333333;
        font-size: 20px;
        font-weight: 700;
        margin-left: 10px;
        &.value-empty{
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    >.night-long{
      font-weight: 700;
      color: #333333;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 35px;
      height: 22px;
      line-height: 22px;
      border-radius: 12px;
      text-align: center;
      font-size: 14px;
    }
  }
  >.week{
    width: 100%;
    height: 20px;
    background-color: #f8f8f8;
    -webkit-display: flex;
    display: flex;
    >span{
      -webkit-flex: 1;
      flex: 1;
      text-align: center;
      height: 20px;
      line-height: 20px;
      color: #A3A9AF;
      font-size: 11px;
      &.weekend{
        color: #BC8B4C;
      }
    }
  }
  >.days{
    -webkit-flex: 1;
    flex: 1;
    overflow: auto;
    margin-bottom: 76px;
    .days-item{
      border-bottom: solid 1px #E6E6E6;
      position: relative;
      padding-bottom: 10px;
      &:last-child{
        border-bottom: none;
      }
      .big-month{
        position: absolute;
        z-index: 2;
        left:  50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 240px;
        text-align: center;
        opacity: 0.1;
      }
      .year-month{
        height: 40px;
        line-height: 40px;
        color: #333333;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }
      .day-list{
        overflow: hidden;
        position: relative;
        z-index: 3;
        >.day-list-item{
          width: calc(~"100% / 7");
          padding-top: calc(~"100% / 7");
          float: left;
          border-radius: 8px;
          position: relative;
          >em{
            display: none;
          }
          &.disabled{
            >.inner{
              font-weight: 400;
              color: rgba(51, 51, 51, 0.65);
            }
          }
          &.select-start{
            background-color: #E4CBA3;
            >.inner{
              font-weight: 700;
              font-size: 18px;
              color: #000000;
              &:before{
                position: absolute;
                content: '入住';
                color: #000000;
                font-size: 10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                top: 3px;
              }
            }
          }
          &.select-start-end{
            //background-color: rgba(228, 203, 163, 0.45);
            position: relative;
            >em{
              position: absolute;
              left: -10px;
              right: -10px;
              top: 0;
              bottom: 0;
              background-color: rgba(228, 203, 163, 0.75);
              content: '';
              display: block;
            }
            >.inner{
              font-weight: 700;
              font-size: 18px;
              color: #000000;
              z-index: 2;
            }
          }
          &.select-end{
            background-color: #C3A87C;
            >.inner{
              font-weight: 700;
              font-size: 18px;
              color: #000000;
              &:before{
                position: absolute;
                content: '离店';
                color: #000000;
                font-size: 10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                top: 3px;
              }
            }
          }
          &.today{
            >.inner{
              font-weight: 400;
              &:before{
                position: absolute;
                content: '今天';
                color: #000000;
                font-size: 10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                top: 3px;
              }
            }
          }
          &.xiu{
            >.inner{
              font-weight: 400;
              &:after{
                position: absolute;
                content: '休';
                font-size: 10px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                bottom: 1px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: rgba(51, 51, 51, 0.25);
                color: #fff;
                text-align: center;
                line-height: 16px;
              }
            }
          }
          >.inner{
            font-weight: 700;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            -webkit-display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
          }
        }
      }
    }
  }
  >.submit-btn-wrap{
    background-color: #ffffff;
    height: 76px;
    -webkit-display: flex;
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    .submit-btn{
      background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
      background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
      background: linear-gradient(left, #E4CBA3, #C3A87C);
      margin: 0 16px;
      border-radius: 40px;
      color: #ffffff;
      height: 50px;
      -webkit-flex: 1;
      flex: 1;
      -webkit-display: flex;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      >span{
        font-size: 12px;
        text-align: center;
      }
      >label{
        font-size: 14px;
      }
    }
  }
}