.list-loading {
  text-align: center;
  font-size: 12px;
  margin: 10px 0 20px;
}
.list-loading > em {
  display: inline-block;
  color: #F00000;
  -webkit-animation: loading_rotate 1s infinite;
  animation: loading_rotate 1s infinite;
  margin-right: 2px;
  position: relative;
  top: 1px;
}
.list-loading-end {
  color: rgba(204, 204, 204, 0.7);
  text-align: center;
  margin: 10px 0 20px;
  position: relative;
}
.list-loading-end:before {
  position: absolute;
  left: 16px;
  width: 36%;
  content: '';
  height: 1px;
  background-color: rgba(204, 204, 204, 0.4);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.list-loading-end:after {
  position: absolute;
  right: 16px;
  width: 36%;
  content: '';
  height: 1px;
  background-color: rgba(204, 204, 204, 0.4);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
