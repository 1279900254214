.book-wrap {
  flex: 1;
  -webkit-flex: 1;
  padding-bottom: 66px;
}
.book-wrap > .hotel-info {
  height: 172px;
  background-color: #EEDFC7;
  padding: 0 16px;
  overflow: hidden;
  position: relative;
}
.book-wrap > .hotel-info .title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 24px;
  position: relative;
  z-index: 2;
}
.book-wrap > .hotel-info .date {
  margin-top: 16px;
  background-color: #ffffff;
  border-radius: 2px;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  height: 60px;
  padding: 0 16px;
}
.book-wrap > .hotel-info .date > .date-item {
  -webkit-display: flex;
  display: flex;
  align-items: center;
}
.book-wrap > .hotel-info .date > .date-item > .value {
  font-size: 20px;
  font-weight: 700;
  margin-left: 10px;
}
.book-wrap > .hotel-info .date > .date-item > .value.value-empty {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
}
.book-wrap > .hotel-info .date > .night-long {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 35px;
  height: 22px;
  line-height: 22px;
  border-radius: 12px;
  border: 1px solid rgba(51, 51, 51, 0.1);
  text-align: center;
  font-size: 12px;
}
.book-wrap > .hotel-info .tip {
  margin-top: 12px;
  position: relative;
  z-index: 2;
}
.book-wrap > .hotel-info > .hotel-info-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.45;
}
.book-wrap .block-title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 6px;
  position: relative;
}
.book-wrap > .form-block {
  padding: 0 24px;
}
.book-wrap > .form-block > .form-item {
  border-bottom: solid 1px #E6E6E6;
  height: 62px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.book-wrap > .form-block > .form-item > label {
  margin-right: 24px;
  width: 48px;
}
.book-wrap > .form-block > .form-item > input {
  background: transparent;
  border: transparent;
  -webkit-flex: 1;
  flex: 1;
}
.book-wrap > .coupon-block {
  padding: 0 24px;
}
.book-wrap > .coupon-block .coupon-area {
  padding: 12px 16px;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.book-wrap > .coupon-block .coupon-area.disabled {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
.book-wrap > .coupon-block .coupon-area > span {
  width: 80px;
  position: relative;
  z-index: 2;
}
.book-wrap > .coupon-block .coupon-area > .info {
  -webkit-display: flex;
  display: flex;
  position: relative;
  z-index: 2;
}
.book-wrap > .coupon-block .coupon-area > .info > span {
  color: rgba(51, 51, 51, 0.45);
}
.book-wrap > .coupon-block .coupon-area > .info > em {
  width: 9px;
  height: 16px;
  margin-left: 16px;
  margin-top: 3px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.book-wrap > .coupon-block .coupon-area > .info .coupon-detail {
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
}
.book-wrap > .coupon-block .coupon-area > .info .coupon-detail > span {
  color: #BC8B4C;
  margin-bottom: 8px;
}
.book-wrap > .coupon-block .coupon-area > .info .coupon-detail > span:last-child {
  margin-bottom: 0;
}
.book-wrap > .coupon-block .coupon-area > .opacity-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.04;
}
.book-wrap > .special-block {
  padding: 0 24px;
  margin-bottom: 24px;
}
.book-wrap > .special-block > .block-title {
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book-wrap > .special-block > .block-title > em {
  font-weight: 100;
  width: 11px;
  height: 10px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  right: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.book-wrap > .special-block > .block-title > em.active {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.book-wrap > .special-block .special-content {
  color: rgba(51, 51, 51, 0.65);
  font-size: 12px;
  line-height: 20px;
}
.book-wrap > .special-block .special-living {
  margin-top: 16px;
}
.book-wrap > .special-block .special-living > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}
.book-wrap > .special-block .special-living .special-item {
  height: 34px;
}
.book-wrap > .special-block .special-living .special-item > span {
  display: inline-block;
  padding: 2px 9px;
  border: solid 1px #E6E6E6;
  border-radius: 2px;
  margin-right: 12px;
}
.book-wrap > .special-block .special-living .special-item > span.active {
  color: #BC8B4C;
  border: solid 1px #BC8B4C;
}
.book-wrap > .special-block .special-living .special-item > span:last-child {
  margin-right: 0;
}
.book-wrap > .special-block .special-else {
  margin-top: 16px;
}
.book-wrap > .special-block .special-else > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}
.book-wrap > .special-block .special-else > .else-area {
  height: 80px;
  border: solid 1px #E6E6E6;
  border-radius: 2px;
  position: relative;
  -webkit-display: flex;
  display: flex;
  padding: 12px;
}
.book-wrap > .special-block .special-else > .else-area > textarea {
  background: transparent;
  border: transparent;
}
.book-wrap > .bottom-block {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  height: 66px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 100;
}
.book-wrap > .bottom-block > .price {
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.book-wrap > .bottom-block > .price .book-value {
  color: #FF4F3F;
  font-size: 20px;
  font-weight: 700;
}
.book-wrap > .bottom-block > .price .book-value > i {
  font-size: 12px;
  font-weight: 400;
}
.book-wrap > .bottom-block > .price .coupon-value {
  color: rgba(51, 51, 51, 0.65);
  font-size: 12px;
}
.book-wrap > .bottom-block > .info-block {
  -webkit-display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.book-wrap > .bottom-block .price-detail {
  color: #BC8B4C;
  font-size: 12px;
  margin-right: 19px;
}
.book-wrap > .bottom-block .price-detail > em {
  font-weight: 100;
  width: 10px;
  height: 10px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  right: 0;
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: relative;
  top: -3px;
}
.book-wrap > .bottom-block .price-detail > em.active {
  top: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.book-wrap > .bottom-block .submit-btn {
  border-radius: 100px;
  color: #fff;
  font-size: 18px;
  width: 120px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-weight: 700;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
}
.book-wrap .price-detail-content-mask {
  background-color: rgba(18, 19, 20, 0.5);
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.book-wrap .price-detail-content {
  max-height: 166px;
  background-color: #fff;
  border-bottom: solid 1px #E6E6E6;
  position: fixed;
  bottom: 66px;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 16px;
  z-index: 100;
}
.book-wrap .price-detail-content > .title {
  font-size: 16px;
  font-weight: 700;
}
.book-wrap .price-detail-content > .details {
  margin-top: 8px;
}
.book-wrap .price-detail-content > .details > .detail-item {
  height: 26px;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.book-wrap .price-detail-content > .details > .detail-item > label {
  color: rgba(51, 51, 51, 0.85);
  text-align: left;
}
.book-wrap .price-detail-content > .details > .detail-item > span {
  color: #121314;
  text-align: right;
}
.book-wrap .confirm-book-mask {
  background-color: rgba(18, 19, 20, 0.5);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.book-wrap .confirm-book {
  background-color: #fff;
  position: fixed;
  z-index: 102;
  left: 24px;
  right: 24px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-radius: 4px;
}
.book-wrap .confirm-book > .btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-top: 1px solid #E6E6E6;
}
.book-wrap .confirm-book > .content {
  height: 180px;
  overflow: auto;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  padding: 24px;
}
.book-wrap .confirm-book > .content .c-item {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  margin-bottom: 16px;
  opacity: 0;
}
.book-wrap .confirm-book > .content .c-item.ci1 {
  opacity: 1;
  transition: opacity 0.25s linear 0.5s;
}
.book-wrap .confirm-book > .content .c-item.ci2 {
  opacity: 1;
  transition: opacity 0.25s linear 1s;
}
.book-wrap .confirm-book > .content .c-item.ci3 {
  opacity: 1;
  transition: opacity 0.25s linear 1.5s;
}
.book-wrap .confirm-book > .content .c-item.ci4 {
  opacity: 1;
  transition: opacity 0.25s linear 2s;
}
.book-wrap .confirm-book > .content .c-item.ci5 {
  opacity: 1;
  transition: opacity 0.25s linear 2.5s;
}
.book-wrap .confirm-book > .content .c-item.ci6 {
  opacity: 1;
  transition: opacity 0.25s linear 3s;
}
.book-wrap .confirm-book > .content .c-item.ci7 {
  opacity: 1;
  transition: opacity 0.25s linear 3.5s;
}
.book-wrap .confirm-book > .content .c-item:last-child {
  margin-bottom: 0;
}
.book-wrap .confirm-book > .content .c-item > label {
  font-size: 16px;
}
.book-wrap .confirm-book > .content .c-item > i {
  width: 11px;
  height: 9px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.book-wrap .calendar-wrap-mask {
  background-color: rgba(18, 19, 20, 0.5);
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.book-wrap .calendar-container {
  overflow: hidden;
  position: fixed;
  z-index: 102;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: transform 0.25s;
  transition: transform 0.25s;
}
.book-wrap .calendar-container.calendar-container-out {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: transform 0.25s;
}
.book-wrap .coupons-container {
  overflow: hidden;
  position: fixed;
  z-index: 102;
  left: 0;
  top: 44px;
  right: 0;
  bottom: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.25s;
  transition: transform 0.25s;
}
.book-wrap .coupons-container.coupons-container-out {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: transform 0.25s;
}
