.hotel-item-container {
  padding: 0 16px 10px 16px;
  border-bottom: solid 1px #E6E6E6;
  margin-bottom: 16px;
  display: block;
}
.hotel-item-container:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.hotel-item-container > .base-info {
  -webkit-display: flex;
  display: flex;
  margin-bottom: 8px;
}
.hotel-item-container > .base-info > .cover {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.25);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 4px;
  margin-right: 10px;
}
.hotel-item-container > .base-info > .msg {
  color: #333;
  position: relative;
  height: 100px;
  -webkit-flex: 1;
  flex: 1;
  padding: 4px 0 8px;
}
.hotel-item-container > .base-info > .msg .hotel-name {
  font-size: 16px;
  height: 18px;
  line-height: 18px;
  font-weight: 700;
  overflow: hidden;
}
.hotel-item-container > .base-info > .msg .hotel-enname {
  font-size: 12px;
  line-height: 14px;
  height: 14px;
  overflow: hidden;
  margin-top: 2px;
}
.hotel-item-container > .base-info > .msg .hotel-tags {
  margin-top: 4px;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 20px;
  overflow: hidden;
}
.hotel-item-container > .base-info > .msg .hotel-tags > span {
  width: 28px;
  height: 14px;
  line-height: 14px;
  border: 1px solid rgba(51, 51, 51, 0.45);
  color: rgba(51, 51, 51, 0.65);
  font-size: 10px;
  border-radius: 2px;
  margin-right: 4px;
  padding: 0 4px;
}
.hotel-item-container > .base-info > .msg .hotel-tags > span:last-child {
  margin-right: 0;
}
.hotel-item-container > .base-info > .msg .price-and-poi {
  position: absolute;
  bottom: 0;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .poi {
  position: relative;
  bottom: 2px;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price {
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  text-align: right;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price > .ori-price {
  color: #858585;
  font-size: 12px;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price > .ori-price > .price-value {
  font-weight: inherit;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price > .price-value {
  color: #FF4F3F;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price > .price-value > .yen {
  font-size: 12px;
  font-weight: normal;
}
.hotel-item-container > .base-info > .msg .price-and-poi > .price > .order-now {
  width: 72px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 12px;
  font-size: 14px;
}
.hotel-item-container > .other-info {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.hotel-item-container > .other-info .distance {
  font-size: 12px;
  color: #333;
  width: 100px;
  align-self: flex-start;
  height: 14px;
  line-height: 14px;
  position: absolute;
  left: 0;
  top: 0;
}
.hotel-item-container > .other-info .tags {
  -webkit-flex: 1;
  flex: 1;
  overflow: hidden;
  margin-left: 110px;
  text-align: right;
  margin-bottom: -2px;
}
.hotel-item-container > .other-info .tags > span {
  border-radius: 2px;
  border: solid 1px;
  padding: 2px 4px;
  height: 12px;
  line-height: 12px;
  display: inline-block;
  box-sizing: content-box;
  margin-left: 4px;
  margin-bottom: 2px;
}
.hotel-item-container > .other-info .tags > span:first-child {
  margin-left: 0;
}
