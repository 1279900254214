.coupons-wrap {
  flex: 1;
  -webkit-flex: 1;
  background-color: #F2F2F2;
  padding-bottom: 66px;
  position: relative;
  height: 100%;
  overflow: auto;
}
.coupons-wrap .list-wrap {
  padding: 16px;
  position: relative;
  flex: 1;
  -webkit-flex: 1;
  overflow: auto;
  height: 100%;
}
.coupons-wrap .list-wrap > .title {
  color: rgba(51, 51, 51, 0.45);
  font-size: 14px;
  text-align: center;
  position: relative;
  margin-bottom: 16px;
}
.coupons-wrap .list-wrap > .title > span {
  background-color: #F2F2F2;
  position: relative;
  z-index: 2;
  padding: 0 16px;
}
.coupons-wrap .list-wrap > .title:before {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '';
  width: 238px;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.25);
}
.coupons-wrap .list-wrap > .item {
  background-color: #ffffff;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  height: 92px;
  align-items: center;
  padding: 0 24px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 14px;
}
.coupons-wrap .list-wrap > .item:last-child {
  margin-bottom: 0;
}
.coupons-wrap .list-wrap > .item > .info > .name {
  color: #060607;
  font-size: 16px;
  font-weight: 700;
}
.coupons-wrap .list-wrap > .item > .info > .name > span.number {
  color: rgba(51, 51, 51, 0.45);
  font-size: 12px;
  margin-left: 4px;
}
.coupons-wrap .list-wrap > .item > .info > .date {
  color: #676769;
  font-size: 12px;
  margin-top: 13px;
}
.coupons-wrap .list-wrap > .item > .selector {
  border-radius: 50%;
  border: solid 1px #E6E6E6;
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.coupons-wrap .list-wrap > .item.disabled {
  color: rgba(51, 51, 51, 0.25);
}
.coupons-wrap .list-wrap > .item.disabled > .info > .name {
  color: rgba(51, 51, 51, 0.25);
}
.coupons-wrap .list-wrap > .item.disabled > .info > .name > span.number {
  display: none;
}
.coupons-wrap .list-wrap > .item.disabled > .info > .date {
  color: rgba(51, 51, 51, 0.25);
}
.coupons-wrap .list-wrap > .item.disabled > .selector {
  display: none;
}
.coupons-wrap .list-back {
  background-color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  z-index: 3;
}
.coupons-wrap .list-back:before {
  position: absolute;
  left: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: '';
  background-image: url('../../common/images/back.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 14px;
  height: 14px;
}
.coupons-wrap .ctrl-wrap {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: 68px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #ffffff;
  z-index: 3;
}
.coupons-wrap .ctrl-wrap > span {
  color: rgba(51, 51, 51, 0.65);
  font-size: 12px;
}
.coupons-wrap .ctrl-wrap > .btns-wrap {
  -webkit-display: flex;
  display: flex;
}
.coupons-wrap .ctrl-wrap > .btns-wrap .cancel-btn {
  color: #ffffff;
  font-size: 18px;
  width: 88px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  border-radius: 100px;
  color: rgba(51, 51, 51, 0.85);
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
}
.coupons-wrap .ctrl-wrap > .btns-wrap .submit-btn {
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
  color: #ffffff;
  font-size: 18px;
  width: 88px;
  line-height: 36px;
  height: 36px;
  text-align: center;
  border-radius: 100px;
  margin-left: 8px;
}
