.hotel-img-list-wrap{
  >.img-filters{
    padding: 0 20px;
    border-bottom: solid 1px rgba(195, 168, 124, 0.45);
    overflow: auto;
    >.img-filters-wrap{
      width: 1000px;
      overflow: hidden;
      >.filter-item{
        font-size: 14px;
        color: #333;
        position: relative;
        float: left;
        height: 48px;
        line-height: 48px;
        margin-right: 25px;
        &:last-child{
          margin-right: 0;
        }
        &.able{
          color: #BC8B4C;
          &:after{
            background-color: #BC8B4C;
          }
        }
        &:after{
          position: absolute;
          content: '';
          height: 3px;
          background-color: transparent;
          left: 0;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  >.img-list-container{
    padding: 12px;
    overflow: hidden;
    >.img-item{
      float: left;
      width: calc(~ "50% - 4px");
      padding-top: 35%;
      background-color: rgba(0, 0, 0, 0.25);
      margin-bottom: 8px;
      border-radius: 2px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      &:nth-child(2n) {
        float: right;
      }
    }
  }
}