.user-rights-wrap {
  flex: 1;
  -webkit-flex: 1;
  background-color: #F2F2F2;
  padding-bottom: 50px;
}
.user-rights-wrap > .rights {
  overflow: hidden;
}
.user-rights-wrap > .rights > .title {
  color: #12151B;
  font-size: 16px;
  padding: 32px 24px 24px;
}
.user-rights-wrap > .rights > .list {
  padding: 0 24px;
}
.user-rights-wrap > .rights > .list > .item {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: -webkit-linear-gradient(left, #FCE7C6, #E7C49B);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #FCE7C6, #E7C49B);
  background: -moz-linear-gradient(left, #FCE7C6, #E7C49B);
  background: linear-gradient(left, #FCE7C6, #E7C49B);
  position: relative;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  float: left;
  margin-right: 29px;
  margin-bottom: 48px;
}
.user-rights-wrap > .rights > .list > .item:nth-child(4n) {
  margin-right: 0;
}
.user-rights-wrap > .rights > .list > .item > span {
  position: absolute;
  top: 0;
  right: 16px;
  background: -webkit-linear-gradient(left, #292F3A, #12151B);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #292F3A, #12151B);
  background: -moz-linear-gradient(left, #292F3A, #12151B);
  background: linear-gradient(left, #292F3A, #12151B);
  color: #ffffff;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  margin-left: -10px;
}
.user-rights-wrap > .rights > .list > .item > label {
  color: #000000;
  font-size: 14px;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  text-align: center;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  height: 22px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-rights-wrap > .coupons {
  overflow: hidden;
}
.user-rights-wrap > .coupons > .title {
  color: #12151B;
  font-size: 16px;
  margin: 32px 24px 24px;
  position: relative;
  box-sizing: border-box;
}
.user-rights-wrap > .coupons > .title > .active2 {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 8px;
  border: solid 1px #dc590a;
  color: #dc590a;
  transform: translateY(-18%);
  font-size: 14px;
  border-radius: 4px;
}
.user-rights-wrap > .coupons > .list {
  padding: 0 24px;
}
.user-rights-wrap > .coupons > .list > .item {
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}
.user-rights-wrap > .coupons > .list > .item:last-child {
  margin-bottom: 0;
}
.user-rights-wrap > .coupons > .list > .item > .msg {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 18px 0 24px;
}
.user-rights-wrap > .coupons > .list > .item > .msg > .info > .name {
  color: #060607;
  font-size: 16px;
  font-weight: 700;
}
.user-rights-wrap > .coupons > .list > .item > .msg > .info > .name > span.number {
  color: rgba(51, 51, 51, 0.45);
  font-size: 12px;
  margin-left: 4px;
}
.user-rights-wrap > .coupons > .list > .item > .msg > .info > .date {
  color: #676769;
  font-size: 12px;
  margin-top: 13px;
}
.user-rights-wrap > .coupons > .list > .item > .msg > em {
  width: 44px;
  height: 44px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.user-rights-wrap > .coupons > .list > .item > .tip {
  padding: 0 18px 0 24px;
  color: #676769;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  border-top: dashed 1px #EFEFEF;
  position: relative;
}
.user-rights-wrap > .coupons > .list > .item > .tip:before {
  width: 8px;
  height: 8px;
  background-color: #F2F2F2;
  border-radius: 50%;
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.user-rights-wrap > .coupons > .list > .item > .tip:after {
  width: 8px;
  height: 8px;
  background-color: #F2F2F2;
  border-radius: 50%;
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.user-rights-wrap > .coupons > .history-list .toggle {
  color: #956010;
  text-align: center;
  padding: 40px 0 22px;
}
.user-rights-wrap > .coupons > .history-list .toggle > em {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 10px;
  margin-left: 8px;
  position: relative;
  top: -3px;
}
.user-rights-wrap > .coupons > .history-list > .title {
  color: rgba(51, 51, 51, 0.45);
  font-size: 14px;
  text-align: center;
  position: relative;
  margin: 40px 0 22px;
}
.user-rights-wrap > .coupons > .history-list > .title > span {
  background-color: #F2F2F2;
  position: relative;
  z-index: 2;
  padding: 0 16px;
}
.user-rights-wrap > .coupons > .history-list > .title:before {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  content: '';
  width: 238px;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.25);
}
.user-rights-wrap > .coupons > .history-list > .item > .msg > .info > .name {
  color: rgba(51, 51, 51, 0.45);
}
.user-rights-wrap > .coupons > .history-list > .item > .msg > .info > .name > span.number {
  color: rgba(51, 51, 51, 0.45);
}
.user-rights-wrap > .coupons > .history-list > .item > .msg > .info > .date {
  color: rgba(51, 51, 51, 0.45);
}
.user-rights-wrap > .coupons > .history-list > .item > .msg > em {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
.user-rights-wrap .empty-txt {
  color: rgba(51, 51, 51, 0.45);
  font-size: 14px;
  text-align: center;
  position: relative;
  margin: 40px 0 22px;
}
.user-rights-wrap .empty-txt > span {
  background-color: #F2F2F2;
  position: relative;
  z-index: 2;
  padding: 0 16px;
}
.user-rights-wrap .empty-txt:before {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  content: '';
  width: 238px;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.25);
}
