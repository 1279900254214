.login-wrap {
  -webkit-flex: 1;
  flex: 1;
  background: -webkit-linear-gradient(#12151B, #222222);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(#12151B, #222222);
  background: -moz-linear-gradient(#12151B, #222222);
  background: linear-gradient(#12151B, #222222);
  padding: 16px 0;
  position: relative;
}
.login-wrap > .icon {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 110px;
  height: 30px;
  margin: 0 0 0 16px;
}
.login-wrap > .icon-new {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 110px;
  margin: 0 0 0 16px;
}
.login-wrap > .main-wrap {
  background-image: url("../common/images/login_bg_01.png");
  background-size: 100% 100%;
  background-position: 50% 0;
  background-repeat: no-repeat;
  margin: 25px auto 0;
  overflow: hidden;
  padding-top: 130%;
  position: relative;
}
.login-wrap > .main-wrap > .main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.login-wrap > .main-wrap > .main > .title {
  background-image: url("../common/images/login_title_01.png");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 37px;
  margin: 68px 61px;
}
.login-wrap > .main-wrap > .main > .input-area {
  margin: 0 48px;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap {
  border-bottom: solid 1px rgba(195, 168, 124, 0.45);
  padding-bottom: 16px;
  margin-bottom: 42px;
  position: relative;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap:last-child {
  margin-bottom: 0;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > label {
  font-size: 16px;
  color: #C3A87C;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > input {
  margin-left: 24px;
  background: transparent;
  border: transparent;
  color: #C3A87C;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > input::-webkit-input-placeholder {
  color: rgba(195, 168, 124, 0.45);
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > input::-moz-placeholder {
  color: rgba(195, 168, 124, 0.45);
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > .code-btn {
  border-radius: 20px;
  color: #E4CBA3;
  border: solid 1px #E4CBA3;
  width: 88px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -10px;
}
.login-wrap > .main-wrap > .main > .input-area > .input-wrap > .code-btn.disabled {
  color: rgba(195, 168, 124, 0.45);
  border-color: rgba(195, 168, 124, 0.45);
}
.login-wrap > .main-wrap > .main > .submit-btn {
  line-height: 48px;
  height: 48px;
  border-radius: 2px;
  margin: 33px 48px 0;
  text-align: center;
  font-size: 18px;
  color: #583F16;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
}
.login-wrap > .main-wrap > .main > .submit-btn.disabled {
  background: #583F16;
  color: #171A20;
}
.login-wrap > .slogan {
  height: 22px;
  line-height: 22px;
  color: rgba(195, 168, 124, 0.45);
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 16px;
  width: 100%;
}
