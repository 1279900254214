@charset "utf-8";
/*
 * @name 基础样式
 * @usage 重设浏览器默认样式....
 */
@import "../common/iconfront/iconfont.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  font-family: sans-serif;
  color: #333333;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  height: 100%;
  background-color: #222222;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
button {
  border: none;
}
ol,
ul {
  list-style: none;
}
a,
a:hover,
ins {
  text-decoration: none;
  color: #333333;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
select,
textarea,
input {
  font-size: 100%;
  outline: none;
}
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #bbbbbb;
}
textarea {
  resize: none;
}
button,
input,
optgroup,
select,
textarea {
  -webkit-appearance: none;
  /*去掉webkit默认的表单样式*/
  -webkit-tap-highlight-color: transparent;
  /*去掉点击时的蓝色外边框和灰色半透明背景*/
}
.clearfix {
  zoom: 1;
  /* for IE6 IE7 */
}
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.clear {
  clear: both;
  display: block;
  overflow: hidden;
  height: 0;
  line-height: 0;
  font-size: 0;
}
.hide {
  display: none !important;
  visibility: hidden;
}
.block {
  display: block !important;
}
.fl {
  float: left;
  display: inline;
}
.fr {
  float: right;
  display: inline;
}
.tl {
  text-align: left;
}
.tr {
  text-align: right;
}
.yen {
  font-family: Arial;
  font-style: normal;
}
.close {
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close:before {
  width: 18px;
  height: 1px;
  background-color: rgba(51, 51, 51, 0.3);
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.close:after {
  width: 1px;
  height: 18px;
  background-color: rgba(51, 51, 51, 0.3);
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#loading {
  background-image: url("../common/images/loading.svg");
  background-size: 28px 28px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
}
.amap-logo {
  display: none!important;
}
.dialog-toast {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.65);
  color: #ffffff;
  padding: 4px 10px;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s linear 0.75s;
  border-radius: 4px;
}
.dialog-confirm {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 75%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s linear 0.75s;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  padding: 20px;
}
.dialog-confirm > .message {
  font-size: 16px;
  margin-bottom: 30px;
}
.dialog-confirm > .ctrls {
  display: flex;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
.dialog-confirm > .ctrls > .ctrl-item {
  flex: 1;
}
.dialog-confirm > .ctrls > .ctrl-item.ok {
  background-color: #BC8B4C;
  color: #fff;
}
.dialog-confirm > .ctrls > .ctrl-item.cancel {
  background-color: #999;
  color: #fff;
  margin-right: 20px;
}
::-webkit-scrollbar {
  display: none;
}
