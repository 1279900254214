.home-wrap{
  flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
  >.top-base-info{
    overflow: hidden;
    padding: 0 16px;
    position: relative;
    //background: -webkit-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)); /* Safari 5.1 - 6.0 */
    //background: -o-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
    //background: -moz-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
    //background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    //background-image: url("../../src/common/images/home_cover.jpg");
    >.entrances{
      -webkit-display: flex;
      display: flex;
      align-items: center;
      background-color: #12151B;
      height: 55px;
      margin: 16px -16px 0;
      >.entrance-item{
        -webkit-flex: 1;
        flex: 1;
        text-align: center;
        color: #ffffff;
        height: 32px;
        line-height: 32px;
        position: relative;
        font-size: 16px;
        display: block;
        &:after{
          background-color: #C3A87C;
          width: 1px;
          height: 20px;
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          content: '';
        }
        &:last-child{
          &:after{
            display: none;
          }
        }
      }
    }
    >.title{
      font-size: 16px;
      color: #ffffff;
      margin-top: 196px;
      min-height: 22px;
    }
    >.desc{
      font-size: 12px;
      color: #ffffff;
      margin-top: 8px;
      min-height: 17px;
    }
    >.label{
      font-size: 12px;
      color: #C3A87C;
      margin-top: 8px;
    }
    >.rule{
      position: absolute;
      top: 16px;
      right: 16px;
      border: solid 1px #BC8B4C;
      border-radius: 10px;
      color: #BC8B4C;
      font-size: 13px;
      height: 20px;
      width: 64px;
      text-align: center;
      display: block;
    }
  }
  >.bottom-list{
    >.hotels-list{
      margin-top: 24px;
    }
  }

  .first-coupons-mask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }
  .first-coupons-close{
    z-index: 100;
    position: fixed;
    top: 24px;
    right: 24px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url("../common/images/close.png");
    width: 16px;
    height: 16px;
  }
  .first-coupons{
    z-index: 100;
    position: fixed;
    left:50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 82.93%;
    background: -webkit-linear-gradient(#292F3A, #12151B); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(#292F3A, #12151B);
    background: -moz-linear-gradient(#292F3A, #12151B);
    background: linear-gradient(#292F3A, #12151B);
    padding: 24px 0 16px;
    >.title{
      color: #E4CBA3;
      font-size: 24px;
      text-align: center;
      margin-bottom: 32px;
    }
    >.list{
      max-height: 272px;
      overflow: auto;
      >.item{
        background-color: #ffffff;
        border-radius: 2px;
        height: 80px;
        -webkit-display: flex;
        display: flex;
        position: relative;
        overflow: hidden;
        margin: 0 26px 16px;
        &:last-child{
          margin-bottom: 0;
        }
        >.value{
          position: relative;
          width: 30%;
          text-align: center;
          -webkit-display: flex;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FF4F3F;
          font-size: 20px;
          font-weight: 700;
          >em{
            font-weight: 400;
            font-size: 12px;
            position: relative;
            top: 4px;
            margin-right: 4px;
          }
          &:before{
            position: absolute;
            top: 0;
            right: 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #292F3A;
            content: '';
            transform: translate(50%, -50%);
          }
          &:after{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #292F3A;
            content: '';
            transform: translate(50%, 50%);
          }
        }
        >.msg-wrap{
          border-left: dashed 1px rgba(195, 168, 124, 0.45);
          padding: 0 16px;
          -webkit-display: flex;
          display: flex;
          align-items: center;
          width: 100%;
          .msg{
            -webkit-display: flex;
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          .name{
            color: #FF4F3F;
            font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .desc{
            color: rgba(51, 51, 51, 0.85);
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .expired{
            color: rgba(51, 51, 51, 0.45);
            font-size: 12px;
          }
        }
      }
    }
    >.get-btn{
      background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
      background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
      background: linear-gradient(left, #E4CBA3, #C3A87C);
      margin: 16px 26px 0;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 18px;
      color: #583F16;
    }
  }
}