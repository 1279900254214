.hotel-detail-wrap {
  background-color: #F2F2F2;
}
.hotel-detail-wrap .block-wrap {
  background-color: #ffffff;
  padding: 16px;
  overflow: hidden;
  margin-bottom: 8px;
  position: relative;
}
.hotel-detail-wrap .block-wrap:last-child {
  margin-bottom: 0;
}
.hotel-detail-wrap .banner-list-wrap {
  padding-top: 51%;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 16px;
}
.hotel-detail-wrap .banner-list-wrap .index-wrap {
  position: absolute;
  bottom: 8px;
  width: 100%;
  left: 0;
  height: 12px;
  z-index: 99;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hotel-detail-wrap .banner-list-wrap .index-wrap > em {
  width: 6px;
  height: 6px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: #ffffff;
}
.hotel-detail-wrap .banner-list-wrap .index-wrap > em.active {
  background-color: #BC8B4C;
}
.hotel-detail-wrap .banner-list {
  position: absolute!important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.hotel-detail-wrap .banner-list > div {
  position: absolute!important;
  top: 0;
  height: 100%;
}
.hotel-detail-wrap .banner-list .banner-item {
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.15);
}
.hotel-detail-wrap .banner-list > .count {
  position: absolute;
  width: 52px;
  height: 24px;
  right: 8px;
  bottom: 8px;
  text-align: center;
  line-height: 24px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  color: #ffffff;
  border-radius: 12px;
}
.hotel-detail-wrap .baseinfo {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotel-detail-wrap .baseinfo .title {
  font-weight: 700;
  font-size: 20px;
}
.hotel-detail-wrap .baseinfo .en-title {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}
.hotel-detail-wrap .baseinfo .poi {
  font-size: 18px;
}
.hotel-detail-wrap .desc-wrap {
  position: relative;
}
.hotel-detail-wrap .desc-wrap > .content-hidden {
  visibility: hidden;
  position: absolute;
}
.hotel-detail-wrap .desc-wrap > .content {
  max-height: 66px;
  font-size: 16px;
  margin-top: 16px;
  overflow: hidden;
  position: relative;
}
.hotel-detail-wrap .desc-wrap > .content-all {
  font-size: 16px;
  margin-top: 16px;
  overflow: hidden;
  position: relative;
}
.hotel-detail-wrap .desc-wrap .toggle-btn {
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.hotel-detail-wrap .desc-wrap .toggle-btn > em {
  width: 8px;
  height: 12px;
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.hotel-detail-wrap .desc-wrap .toggle-btn.open-btn {
  position: absolute;
  bottom: 0;
  right: 7px;
  padding-left: 20px;
  background: -webkit-linear-gradient(left, transparent, #fff 15%);
  background: -o-linear-gradient(right, transparent, #fff 15%);
  background: -moz-linear-gradient(right, transparent, #fff 15%);
  background: linear-gradient(to right, transparent, #fff 15%);
}
.hotel-detail-wrap .desc-wrap .toggle-btn.open-btn > em {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.hotel-detail-wrap .desc-wrap .toggle-btn.close-btn {
  text-align: center;
  height: 16px;
  line-height: 16px;
  position: relative;
  margin-top: 10px;
}
.hotel-detail-wrap .desc-wrap .toggle-btn.close-btn > em {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.hotel-detail-wrap .address {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.hotel-detail-wrap .address > .content {
  -webkit-flex: 1;
  flex: 1;
}
.hotel-detail-wrap .address > .content .info {
  -webkit-display: flex;
  display: flex;
  min-height: 40px;
}
.hotel-detail-wrap .address > .content .info > label {
  font-weight: 700;
  width: 42px;
}
.hotel-detail-wrap .address > .content .info > span {
  -webkit-flex: 1;
  flex: 1;
}
.hotel-detail-wrap .address > .content .guide-btn {
  border: solid 1px #E6E6E6;
  border-radius: 4px;
  margin-top: 8px;
  width: 60px;
  text-align: center;
  height: 24px;
  line-height: 24px;
}
.hotel-detail-wrap .address > .map {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.45);
  margin-left: 30px;
}
.hotel-detail-wrap .bedtype-item-wrap {
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 8px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item {
  -webkit-display: flex;
  display: flex;
  border-bottom: solid 1px #E6E6E6;
  padding: 16px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item:last-child {
  border-bottom: none;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .cover {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  margin-right: 16px;
  background-color: rgba(0, 0, 0, 0.45);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message {
  -webkit-flex: 1;
  flex: 1;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .params {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 12px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .params > span {
  margin-right: 10px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .params > span:last-child {
  margin-right: 0;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .coupons {
  position: relative;
  padding-right: 62px;
  margin-bottom: -6px;
  min-height: 24px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .coupons .proposed-price {
  text-decoration: line-through;
  line-height: 20px;
  color: #858585;
  font-size: 12px;
  position: absolute;
  top: -20px;
  right: 0;
  width: 52px;
  text-align: center;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .coupons > span {
  font-size: 12px;
  border: solid 1px;
  box-sizing: border-box;
  border-radius: 2px;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 6px;
  padding: 0 4px;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .coupons > span:last-child {
  margin-right: 0;
}
.hotel-detail-wrap .bedtype-item-wrap .bedtype-item > .message > .coupons > .order {
  display: block;
  top: 0;
  position: absolute;
  right: 0;
  float: right;
  width: 52px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
}
.hotel-detail-wrap .tips {
  padding-bottom: 22px;
}
.hotel-detail-wrap .tips > .title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
}
.hotel-detail-wrap .tips > .tip-list .tip-item {
  margin-bottom: 8px;
  -webkit-display: flex;
  display: flex;
}
.hotel-detail-wrap .tips > .tip-list .tip-item > label {
  color: #858585;
  margin-right: 16px;
  width: 58px;
}
.hotel-detail-wrap .tips > .tip-list .tip-item > span {
  -webkit-flex: 1;
  flex: 1;
}
