.textOverflowMulti(@line: 3, @bg: #fff) {
  position: relative;
  //max-height: @line * 1.5em;
  margin-right: -1em;
  padding-right: 1em;
  overflow: hidden;
  //line-height: 1.5em;
  text-align: justify;
  &::before {
    position: absolute;
    right: 14px;
    bottom: 0;
    padding: 0 1px;
    background: @bg;
    content: '...';
  }
  &::after {
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
    content: '';
  }
}

.hotel-detail-wrap{
  background-color: #F2F2F2;
  .block-wrap {
    background-color: #ffffff;
    padding: 16px;
    overflow: hidden;
    margin-bottom: 8px;
    position: relative;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .banner-list-wrap{
    padding-top: 51%;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 16px;
    .index-wrap{
      position: absolute;
      bottom: 8px;
      width: 100%;
      left: 0;
      height: 12px;
      z-index: 99;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      >em{
        width: 6px;
        height: 6px;
        margin-right: 4px;
        border-radius: 50%;
        background-color: #ffffff;
        &.active{
          background-color: #BC8B4C;
        }
      }
    }
  }
  .banner-list{
    position: absolute!important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    >div{
      position: absolute!important;
      top: 0;
      height: 100%;
    }
    .banner-item{
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.15);
    }
    >.count{
      position: absolute;
      width: 52px;
      height: 24px;
      right: 8px;
      bottom: 8px;
      text-align: center;
      line-height: 24px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 15px;
      color: #ffffff;
      border-radius: 12px;
    }
  }
  .baseinfo{
    -webkit-display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title{
      font-weight: 700;
      font-size: 20px;
    }
    .en-title{
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
    }
    .poi{
      //color: #BC8B4C;
      font-size: 18px;
    }
  }
  .desc-wrap{
    position: relative;
    //margin-bottom: 16px;
    >.content-hidden{
      visibility: hidden;
      position: absolute;
    }
    >.content{
      max-height: 66px;
      font-size: 16px;
      margin-top: 16px;
      overflow: hidden;
      position: relative;
    }
    >.content-all{
      font-size: 16px;
      margin-top: 16px;
      overflow: hidden;
      position: relative;
    }
    .toggle-btn{
      //color: #BC8B4C;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      >em{
        width: 8px;
        height: 12px;
        margin-left: 6px;
        display: inline-block;
        vertical-align: middle;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &.open-btn{
        position: absolute;
        bottom: 0;
        right: 7px;
        padding-left: 20px;
        background: -webkit-linear-gradient(left, transparent, #fff 15%);
        background: -o-linear-gradient(right, transparent, #fff 15%);
        background: -moz-linear-gradient(right, transparent, #fff 15%);
        background: linear-gradient(to right, transparent, #fff 15%);
        >em{
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
      &.close-btn{
        text-align: center;
        height: 16px;
        line-height: 16px;
        position: relative;
        margin-top: 10px;
        >em{
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }
  }

  .address{
    -webkit-display: flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    >.content{
      -webkit-flex: 1;
      flex: 1;
      .info{
        -webkit-display: flex;
        display: flex;
        min-height: 40px;
        >label{
          font-weight: 700;
          width: 42px;
        }
        >span{
          -webkit-flex: 1;
          flex: 1;
        }
      }
      .guide-btn{
        border: solid 1px #E6E6E6;
        border-radius: 4px;
        margin-top: 8px;
        width: 60px;
        text-align: center;
        height: 24px;
        line-height: 24px;
      }
    }
    >.map{
      width: 80px;
      height: 80px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.45);
      margin-left: 30px;
    }
  }

  .bedtype-item-wrap{
    background-color: #ffffff;
    overflow: hidden;
    margin-bottom: 8px;
    .bedtype-item{
      -webkit-display: flex;
      display: flex;
      border-bottom: solid 1px #E6E6E6;
      //margin: 0 -16px;
      padding: 16px;
      &:last-child{
        border-bottom: none;
      }
      >.cover{
        width: 80px;
        height: 80px;
        border-radius: 2px;
        margin-right: 16px;
        background-color: rgba(0, 0, 0, 0.45);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
      }
      >.message{
        -webkit-flex: 1;
        flex: 1;
        >.title{
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 6px;
        }
        >.params{
          font-size: 12px;
          color: rgba(0,0,0,0.65);
          margin-bottom: 12px;
          >span{
            margin-right: 10px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        >.coupons{
          position: relative;
          padding-right: 62px;
          margin-bottom: -6px;
          //overflow: hidden;
          min-height: 24px;
          .proposed-price{
            text-decoration: line-through;
            line-height: 20px;
            color: rgba(133, 133, 133, 1);
            font-size: 12px;
            position: absolute;
            top: -20px;
            right: 0;
            width: 52px;
            text-align: center;
          }
          >span{
            //color: #BC8B4C;
            font-size: 12px;
            border: solid 1px;
            box-sizing: border-box;
            border-radius: 2px;
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 6px;
            padding: 0 4px;
            &:last-child{
              margin-right: 0;
            }
          }
          >.order{
            display: block;
            top: 0;
            position: absolute;
            right: 0;
            float: right;
            //color: #583F16;
            width: 52px;
            text-align: center;
            height: 24px;
            line-height: 24px;
            border-radius: 12px;
            //background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
            //background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
            //background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
            //background: linear-gradient(left, #E4CBA3, #C3A87C);
          }
        }
      }
    }
  }

  .tips{
    padding-bottom: 22px;
    >.title{
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    >.tip-list{
      .tip-item{
        margin-bottom: 8px;
        -webkit-display: flex;
        display: flex;
        >label{
          color: #858585;
          margin-right: 16px;
          width: 58px;
        }
        >span{
          -webkit-flex: 1;
          flex: 1;
        }
      }
    }
  }
}