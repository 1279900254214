.hotel-list-filters-container {
  position: relative;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
  width: 100%;
}
.hotel-list-filters-container > .filter-items-wrapper {
  display: flex;
  -webkit-display: flex;
  position: relative;
  height: 44px;
  align-items: center;
  z-index: 99;
  background-color: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
}
.hotel-list-filters-container .filter-item {
  padding: 0px 12px;
  max-width: 124px;
  box-sizing: border-box;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  -webkit-flex: 1;
  flex: 1;
  text-align: center;
}
.hotel-list-filters-container .filter-item.active > span {
  color: #BC8B4C;
}
.hotel-list-filters-container .filter-item.active > .trial-down {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  border-color: #BC8B4C transparent transparent;
}
.hotel-list-filters-container .filter-item > em {
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 12px;
  height: 16px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  margin-right: 6px;
}
.hotel-list-filters-container .filter-item > span {
  color: #333;
  font-size: 14px;
  margin-right: 8px;
}
.hotel-list-filters-container .filter-item > .trial-down {
  width: 0;
  height: 0;
  border-width: 5px 5px 0;
  border-style: solid;
  border-color: #333 transparent transparent;
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}
.hotel-list-filters-container .dropdown-mask {
  position: fixed;
  z-index: 98;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
}
.hotel-list-filters-container .dropdown {
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  max-height: 300px;
  z-index: 99;
  border-top: solid 1px #E6E6E6;
  overflow: auto;
}
.hotel-list-filters-container .dropdown > .dropdown-item {
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  color: #333333;
  position: relative;
}
.hotel-list-filters-container .dropdown > .dropdown-item:after {
  position: absolute;
  left: 16px;
  right: 16px;
  height: 1px;
  background-color: #E6E6E6;
  content: '';
  bottom: 0;
}
.hotel-list-filters-container .dropdown > .dropdown-item:last-child:after {
  display: none;
}
.hotel-list-filters-container .dropdown > .dropdown-item.selected {
  background-size: 11px 9px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 50%;
}
.hotel-list-filters-container .dropdown > .dropdown-btn {
  -webkit-display: flex;
  display: flex;
  height: 50px;
  line-height: 50px;
}
.hotel-list-filters-container .dropdown > .dropdown-btn > span {
  -webkit-flex: 1;
  flex: 1;
  text-align: center;
  background-color: #F2F2F2;
}
.hotel-list-filters-container .dropdown > .dropdown-btn > span:last-child {
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
}
.hotel-list-filters-container .city-list-mask {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.hotel-list-filters-container .city-list-container {
  overflow: hidden;
  position: fixed;
  z-index: 102;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: transform 0.25s;
  transition: transform 0.25s;
}
.hotel-list-filters-container .city-list-container.city-list-container-out {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: transform 0.25s;
}
