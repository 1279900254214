.payment-result-wrap {
  flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.payment-result-wrap .status-sign {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  background-color: #000000;
  border-radius: 50%;
}
.payment-result-wrap .status-text {
  font-size: 18px;
  color: #333333;
  text-align: center;
  margin-bottom: 4px;
}
.payment-result-wrap .price {
  color: #BC8B4C;
  text-align: center;
  font-size: 14px;
}
.payment-result-wrap .next-entrance {
  margin-top: 40px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-display: flex;
  display: flex;
}
.payment-result-wrap .next-entrance > .item {
  width: 80px;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  border: 1px solid #c3a87c;
  color: #C3A87C;
  font-size: 14px;
  text-align: center;
  margin: 0 12px;
}
