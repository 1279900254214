.payment-wrap{
  flex: 1;
  -webkit-flex: 1;
  background-color: #F2F2F2;
  .payment-content{
    background-color: #ffffff;
    height: 120px;
    -webkit-display: flex;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    >.countdown{
      color: #808285;
      text-align: center;
    }
    >.value{
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      color: #060607;
      >em{
        font-size: 24px;
      }
    }
  }
  .payment-method{
    margin-top: 26px;
    >span{
      color: #060607;
      font-size: 12px;
      margin: 0 0 10px 16px;
      display: block;
    }
    >.list{
      background-color: #ffffff;
      >.item{
        -webkit-display: flex;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #EFEFEF;
        margin-left: 16px;
        height: 60px;
        align-items: center;
        padding-right: 24px;
        &:last-child{
          border: none;
        }
        label{
          color: #060607;
          font-size: 16px;
          margin-left: 16px;
        }
        .content{
          -webkt-display: flex;
          display: flex;
          align-items: center;
        }
        .icon{
          height: 28px;
          width: 28px;
          //background-color: rgba(0, 0, 0, 0.45);
          border-radius: 50%;
          display: block;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          &.wx{
            background-image: url("../common/images/payment-wx.png");
          }
          &.citic{
            background-image: url("../common/images/payment-citic.png");
          }
          &.nbank{
            background-image: url("../common/images/payment-nbank.png");
          }
        }
        .selector{
          height: 28px;
          width: 28px;
          border: solid 1px #E6E6E6;
          box-sizing: border-box;
          border-radius: 50%;
          &.selected{
            //background-image: url("../common/images/selected-round.png");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border: none;
          }
        }
      }
    }
  }
  .payment-action{
    height: 68px;
    background-color: #ffffff;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-display: flex;
    display: flex;
	  &.disabled{
		  >span{
			  background: #dcdcdc!important;
		  }
	  }
    >span{
      -webkit-flex: 1;
      flex: 1;
      margin: 0 16px;
      border-radius: 100px;
      background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
      background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
      background: linear-gradient(left, #E4CBA3, #C3A87C);
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      height: 44px;
      line-height: 44px;
    }
  }
}