.payment-result-wrap{
  flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .status-sign{
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
    background-color: rgb(0,0,0,0.25);
    border-radius: 50%;
  }
  .status-text{
    font-size: 18px;
    color: #333333;
    text-align: center;
    margin-bottom: 4px;
  }
  .price{
    color: #BC8B4C;
    text-align: center;
    font-size: 14px;
  }
  .next-entrance{
    margin-top: 40px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-display: flex;
    display: flex;
    >.item{
      width: 80px;
      height: 28px;
      line-height: 28px;
      border-radius: 2px;
      border: 1px solid rgb(195, 168, 124);
      color: #C3A87C;
      font-size: 14px;
      text-align: center;
      margin: 0 12px;
    }
  }
}