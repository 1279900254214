.city-list {
  height: 76%;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
}
.city-list .title {
  height: 44px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  font-size: 16px;
}
.city-list .title > em {
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  top: 50%;
}
.city-list .current-city {
  height: 60px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  border-bottom: solid 1px #E0E0E0;
}
.city-list .current-city em {
  width: 12px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.city-list .current-city label {
  font-size: 18px;
  font-weight: 700;
  margin-left: 8px;
}
.city-list .current-city span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 4px;
}
.city-list .current-city .all-citys {
  width: 80px;
  line-height: 28px;
  height: 28px;
  border-radius: 100px;
  text-align: center;
  border: solid 1px #E6E6E6;
}
.city-list .national-tabs {
  height: 44px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}
.city-list .national-tabs > span {
  background-color: #ffffff;
  height: 44px;
  line-height: 44px;
  -webkit-flex: 1;
  flex: 1;
  text-align: center;
  font-size: 15px;
  position: relative;
}
.city-list .national-tabs > span.active {
  font-weight: 700;
}
.city-list .national-tabs > span.active > em {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 3px;
  width: 20px;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
  border-radius: 3px;
  content: '';
}
.city-list .city-wrap {
  -webkit-flex: 1;
  flex: 1;
  overflow: auto;
  position: relative;
  padding-top: 32px;
}
.city-list .hot-city {
  padding: 0 16px;
}
.city-list .hot-city > label {
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  margin-bottom: 20px;
  display: block;
}
.city-list .hot-city > .list {
  overflow: hidden;
}
.city-list .hot-city > .list > span {
  color: #333;
  display: inline-block;
  width: 25%;
  text-align: center;
  float: left;
  margin-bottom: 24px;
}
.city-list .all-city {
  padding: 0 16px;
}
.city-list .all-city > label {
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  margin-bottom: 20px;
  display: block;
}
.city-list .all-city > .list {
  -webkit-display: flex;
  display: flex;
  position: relative;
}
.city-list .all-city > .list > em {
  width: 13px;
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  font-style: normal;
  margin-right: 16px;
}
.city-list .all-city > .list > div {
  -webkit-flex: 1;
  flex: 1;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
}
.city-list .all-city > .list > div > span {
  padding-bottom: 24px;
}
