.prdlist-wrap{
  flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
	position: relative;
  >img{
    width: 100%;
    display: block;
  }
	.hotel-wrap{
		position: absolute;
		width: 100%;
		left: 0;
		display: flex;
		flex-direction: column;
		>.hotel-block{
			position: relative;
			>img{
				width: 100%;
				display: block;
			}
			>.prds{
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				>a{
					flex: 1;
					//background-color: rgba(0,0,0,0.45);
				}
			}
		}
	}
}
