.orders-wrap{
  flex: 1;
  -webkit-flex: 1;
  background-color: #F2F2F2;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
  .order-tabs{
    -webkit-display: flex;
    display: flex;
    height: 48px;
    background-color: #fff;
    padding: 0 24px 0 0;
    margin-bottom: 8px;
    >.tab-item{
      -webkit-flex: 1;
      flex: 1;
      height: 48px;
      line-height: 48px;
      display: block;
      position: relative;
      text-align: center;
      >span{
        text-align: center;
        position: relative;
        height: 48px;
        line-heihgt: 48px;
        display: inline-block;
        &:last-child{
          margin-right: 0;
        }
        &.current{
          color: #BC8B4C;
          >em{
            content: '';
            position: absolute;
            //background-color: #BC8B4C;
            bottom: 0;
            height: 3px;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }
  .order-list{
    -webkit-flex: 1;
    flex: 1;
    overflow: auto;
    -webkit-display: flex;
    display: flex;
    flex-direction: column;
    >.order-item{
      background-color: #fff;
      margin-bottom: 8px;
      display: block;
      &:last-child{
        margin-bottom: 0;
      }
      .orderid{
        height: 44px;
        -webkit-display:flex;
        display:flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #E6E6E6;
        padding: 0 16px;
        >span{
          color: rgba(51, 51, 51, 0.65);
          font-size: 14px;
          &.id{
            -webkit-flex: 1;
            flex: 1;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            padding-right: 10px;
          }
          &.status{
            display: inline-block;
            text-align: right;
            //width: 46px;
            color: rgba(188, 139, 76, 1);
          }
        }
      }
      .order-info{
        padding: 12px 16px;
        position: relative;
        .title{
          font-size: 18px;
          font-weight: 700;
        }
        .info{
          margin-top: 8px;
          >span{
            padding-right: 8px;
            margin-right: 8px;
            border-right: solid 1px rgba(51, 51, 51, 0.25);
            &:last-child{
              border-right: none;
            }
          }
        }
        .date{
          margin-top: 8px;
          -webkit-display: flex;
          display: flex;
          flex-direction: column;
          >span{
            margin-bottom: 8px;
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        .price{
          position: absolute;
          right: 16px;
          bottom: 16px;
        }
      }
      .order-ctrls{
        height: 44px;
        -webkit-display:flex;
        display:flex;
        align-items: center;
        padding: 0 16px;
        justify-content: flex-end;
        border-top: solid 1px #E6E6E6;
        .cancel{
          color: #C3A87C;
          border-color: #C3A87C;
          background-color: #fff;
          line-height: 26px;
          height: 26px;
          width: 78px;
          text-align: center;
          border: solid 1px;
          border-radius: 2px;
          margin-left: 8px;
        }
        .primary{
          color: #ffffff;
          background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
          background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
          background: linear-gradient(left, #E4CBA3, #C3A87C);
          line-height: 26px;
          height: 28px;
          width: 80px;
          text-align: center;
          border: solid 1px;
          border-radius: 2px;
          margin-left: 8px;
        }
      }
    }
    >.no-order{
      color: #999;
      text-align: center;
      width: 100%;
      -webkot-flex: 1;
      flex: 1;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      >span{

      }
    }
  }
}