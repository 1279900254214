.user-rights-info-wrap{
  flex: 1;
  -webkit-flex: 1;
  overflow: hidden;
  >.tabs-wrap{
    width: 100%;
    overflow: auto;
    background-color: #222222;
    >.tabs{
      >.item{
        width: 100px;
        height: 72px;
        -webkit-display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #222222;
        color: #ffffff;
        flex-direction: column;
        float: left;
        &.active{
          background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
          background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
          background: linear-gradient(left, #E4CBA3, #C3A87C);
        }
        >em{
          display: block;
          height: 20px;
          background-size: auto 100%;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          width: 100%;
        }
        >label{
          color: #ffffff;
          font-size: 12px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  >.content{
    padding: 48px 16px 0;
    >.title{
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      -webkit-display: flex;
      display: flex;
      justify-content: center;
      flex-direction: column;
      >span{
        &.number{
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    >.desc{
      margin-top: 32px;
      >.title{
        color: #333;
        margin-bottom: 12px;
        font-weight: 700;
      }
      >.detail{
        color: rgba(51, 51, 51, 0.85);
        font-size: 12px;
        margin-bottom: 12px;
      }
      >.list-item{
        color: rgba(51, 51, 51, 0.85);
        font-size: 12px;
        margin-bottom: 12px;
        &:last-child{
          margin-bottom: 0;
        }
        >span{
          background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
          background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
          background: linear-gradient(left, #E4CBA3, #C3A87C);
          text-align: center;
          width: 16px;
          height: 16px;
          line-height: 16px;
          color: #583F16;
          font-size: 12px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
    }
  }
  >.usage-action{
    height: 68px;
    background-color: #ffffff;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-display: flex;
    display: flex;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2);
    >span{
      -webkit-flex: 1;
      flex: 1;
      margin: 0 16px;
      border-radius: 100px;
      background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
      background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
      background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
      background: linear-gradient(left, #E4CBA3, #C3A87C);
      color: #583F16;
      font-size: 18px;
      text-align: center;
      height: 44px;
      line-height: 44px;
    }
  }
}