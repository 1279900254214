.rule-wrap{
  flex: 1;
  -webkit-flex: 1;
  padding: 28px 16px;
  >.title{
    font-size: 16px;
    color: #333333;
    margin-bottom: 14px;
  }
  >.content{
    font-size: 12px;
    color: #333333;
  }
}