.order-detail-wrap {
  flex: 1;
  -webkit-flex: 1;
  padding-bottom: 32px;
}
.order-detail-wrap > .status {
  padding: 0 16px;
  -webkit-display: flex;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}
.order-detail-wrap > .status > .status-text {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
}
.order-detail-wrap > .status > .status-text > .no {
  font-size: 14px;
  font-weight: 400;
}
.order-detail-wrap > .status > .price {
  align-self: flex-start;
}
.order-detail-wrap > .status-toppay-wrap > .status-toppay {
  height: 54px;
  color: rgba(51, 51, 51, 0.85);
  font-size: 16px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 42px;
  margin-top: 24px;
}
.order-detail-wrap > .status-toppay-wrap > .status-toppay .status-text {
  color: #BC8B4C;
  font-size: 20px;
  font-weight: 700;
}
.order-detail-wrap > .status-toppay-wrap > .status-toppay .status-time {
  width: 100%;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-detail-wrap > .status-toppay-wrap > .status-toppay .order-price {
  width: 100%;
  -webkit-display: flex;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order-detail-wrap > .status-toppay-wrap > .status-toppay .time-left {
  position: relative;
  top: -2px;
}
.order-detail-wrap > .status-toppay-wrap > .status-topay-btns {
  height: 80px;
  position: relative;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-detail-wrap > .status-toppay-wrap > .status-topay-btns:after {
  position: absolute;
  left: 16px;
  right: 16px;
  height: 1px;
  background-color: #e6e6e6;
  content: '';
  bottom: 0;
}
.order-detail-wrap > .status-toppay-wrap > .status-topay-btns > span {
  width: 152px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-right: 22px;
  border-radius: 2px;
  border: 1px solid rgba(51, 51, 51, 0.25);
  color: rgba(51, 51, 51, 0.85);
  font-size: 14px;
  background-color: #ffffff;
}
.order-detail-wrap > .status-toppay-wrap > .status-topay-btns > span:last-child {
  margin-right: 0;
}
.order-detail-wrap > .od-info {
  padding: 0 16px;
}
.order-detail-wrap > .od-info .order-id {
  height: 62px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: solid 1px #E6E6E6;
}
.order-detail-wrap > .od-info .order-id > span {
  font-weight: 700;
}
.order-detail-wrap > .od-info .cover {
  background-color: rgba(0, 0, 0, 0.45);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 19%;
  margin-top: 24px;
}
.order-detail-wrap > .od-info .title {
  font-size: 16px;
  margin-top: 8px;
}
.order-detail-wrap > .od-info .address {
  margin-top: 4px;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.order-detail-wrap > .od-info .address > .content {
  -webkit-flex: 1;
  flex: 1;
}
.order-detail-wrap > .od-info .address > .content .info {
  -webkit-display: flex;
  display: flex;
  min-height: 40px;
}
.order-detail-wrap > .od-info .address > .content .info > label {
  font-weight: 700;
  width: 42px;
}
.order-detail-wrap > .od-info .address > .content .info > span {
  -webkit-flex: 1;
  flex: 1;
}
.order-detail-wrap > .od-info .address > .content .guide-btn {
  border: solid 1px #E6E6E6;
  border-radius: 4px;
  margin-top: 8px;
  width: 60px;
  text-align: center;
  height: 24px;
  line-height: 24px;
}
.order-detail-wrap > .od-info .address > .map {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.45);
  margin-left: 30px;
}
.order-detail-wrap > .od-info .hotel-entrance {
  -webkit-display: flex;
  display: flex;
  height: 55px;
  align-items: center;
  border-bottom: solid 1px #E6E6E6;
  position: relative;
  margin-top: 24px;
}
.order-detail-wrap > .od-info .hotel-entrance > .item {
  -webkit-flex: 1;
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #333333;
}
.order-detail-wrap > .od-info .hotel-entrance > .item > em {
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}
.order-detail-wrap > .od-info .hotel-entrance:after {
  position: absolute;
  height: 16px;
  width: 1px;
  background-color: rgba(51, 51, 51, 0.25);
  content: '';
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.order-detail-wrap > .od-info .order-confirm-id {
  height: 70px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: solid 1px #E6E6E6;
}
.order-detail-wrap > .od-info .order-confirm-id > span {
  font-weight: 700;
}
.order-detail-wrap > .od-info .obi-item {
  margin-bottom: 16px;
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.order-detail-wrap > .od-info .obi-item:last-child {
  margin-bottom: 0;
}
.order-detail-wrap > .od-info .obi-item > span {
  font-weight: 700;
}
.order-detail-wrap > .od-info .obi-item > .msg {
  -webkit-display: flex;
  display: flex;
  align-items: center;
}
.order-detail-wrap > .od-info .obi-item > .msg > span {
  font-weight: 700;
  font-size: 16px;
}
.order-detail-wrap > .od-info .obi-item > .msg > i {
  font-style: normal;
  border-radius: 12px;
  height: 22px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 6px;
  border: solid 1px rgba(51, 51, 51, 0.1);
  background-color: rgba(51, 51, 51, 0.1);
  padding: 0 8px 0 6px;
  color: #171A20;
  font-size: 14px;
}
.order-detail-wrap > .od-info .obi-item > .msg > em {
  color: #583F16;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  height: 22px;
  line-height: 22px;
  font-style: normal;
  width: 32px;
  margin: 0 6px;
  text-align: center;
}
.order-detail-wrap > .od-info .obi-item .upgrade-text {
  display: inline-block;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.order-detail-wrap > .od-info .order-book-info {
  border-bottom: solid 1px #E6E6E6;
  padding: 24px 0;
}
.order-detail-wrap > .od-info .special-living {
  padding: 20px 0;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: solid 1px #E6E6E6;
}
.order-detail-wrap > .od-info .special-living > span {
  font-weight: 700;
  margin-left: 40px;
  -webkit-flex: 1;
  flex: 1;
  text-align: right;
}
.order-detail-wrap > .od-info .order-info {
  padding: 24px 0;
}
.order-detail-wrap > .od-info .total-price {
  text-align: right;
}
.order-detail-wrap > .od-info .total-price > label {
  font-size: 16px;
  margin-right: 8px;
}
.order-detail-wrap > .od-info .total-price > span {
  font-size: 20px;
  font-weight: 700;
}
.order-detail-wrap > .od-info .total-price > span > i {
  font-size: 12px;
}
