.order-detail-wrap{
  flex: 1;
  -webkit-flex: 1;
  padding-bottom: 32px;
  >.status{
    //height: 72px;
    padding: 0 16px;
    -webkit-display: flex;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
    >.status-text{
      font-size: 20px;
      font-weight: 700;
      color: #333;
      -webkit-display: flex;
      display: flex;
      flex-direction: column;
      >.no{
        font-size: 14px;
        font-weight: 400;
      }
    }
    >.price{
      align-self: flex-start;
    }
  }
  >.status-toppay-wrap{
    >.status-toppay{
      height: 54px;
      //background-color: #EEDFC7;
      color: rgba(51, 51, 51, 0.85);
      font-size: 16px;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 42px;
      margin-top: 24px;
      .status-text{
        color: #BC8B4C;
        font-size: 20px;
        font-weight: 700;
      }
      .status-time{
        width: 100%;
        -webkit-display: flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .order-price{
        width: 100%;
        -webkit-display: flex;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .time-left{
        position: relative;
        top: -2px;
      }
    }
    >.status-topay-btns{
      height: 80px;
      position: relative;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after{
        position: absolute;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: rgba(230, 230, 230, 1);
        content: '';
        bottom: 0;
      }
      >span{
        width: 152px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin-right: 22px;
        border-radius: 2px;
        border: 1px solid rgba(51, 51, 51, 0.25);
        color: rgba(51, 51, 51, 0.85);
        font-size: 14px;
        background-color: #ffffff;
        &:last-child{
          margin-right: 0;
        }
        &.topay{
          //background: -webkit-linear-gradient(bottom, #F24B4B, #FE6A44); /* Safari 5.1 - 6.0 */
          //background: -o-linear-gradient(bottom, #F24B4B, #FE6A44);
          //background: -moz-linear-gradient(bottom, #F24B4B, #FE6A44);
          //background: linear-gradient(bottom, #F24B4B, #FE6A44);
          //color: #ffffff;
          //border-color: #F24B4B;
        }
      }
    }
  }
  >.od-info{
    padding: 0 16px;
    .order-id{
      height: 62px;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: solid 1px #E6E6E6;
      >span{
        font-weight: 700;
      }
    }
    .cover{
      background-color: rgba(0, 0, 0, 0.45);
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 19%;
      margin-top: 24px;
    }
    .title{
      font-size: 16px;
      margin-top: 8px;
    }
    .address{
      margin-top: 4px;
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      >.content{
        -webkit-flex: 1;
        flex: 1;
        .info{
          -webkit-display: flex;
          display: flex;
          min-height: 40px;
          >label{
            font-weight: 700;
            width: 42px;
          }
          >span{
            -webkit-flex: 1;
            flex: 1;
          }
        }
        .guide-btn{
          border: solid 1px #E6E6E6;
          border-radius: 4px;
          margin-top: 8px;
          width: 60px;
          text-align: center;
          height: 24px;
          line-height: 24px;
        }
      }
      >.map{
        width: 80px;
        height: 80px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.45);
        margin-left: 30px;
      }
    }
    .hotel-entrance{
      -webkit-display: flex;
      display: flex;
      height: 55px;
      align-items: center;
      border-bottom: solid 1px #E6E6E6;
      position: relative;
      margin-top: 24px;
      >.item{
        -webkit-flex: 1;
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #333333;
        >em{
          width: 24px;
          height: 24px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
      &:after{
        position: absolute;
        height: 16px;
        width: 1px;
        background-color: rgba(51, 51, 51, 0.25);
        content: '';
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }
    .order-confirm-id{
      height: 70px;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: solid 1px #E6E6E6;
      >span{
        font-weight: 700;
      }
    }
    .obi-item{
      margin-bottom: 16px;
      -webkit-display: flex;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      &:last-child{
        margin-bottom: 0;
      }
      >label{

      }
      >span{
        font-weight: 700;
      }
      >.msg{
        -webkit-display: flex;
        display: flex;
        align-items: center;
        >span{
          font-weight: 700;
          font-size: 16px;
        }
        >i{
          font-style: normal;
          border-radius: 12px;
          height: 22px;
          line-height: 20px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 6px;
          border: solid 1px rgba(51, 51, 51, 0.1);
          background-color: rgba(51, 51, 51, 0.1);
          padding: 0 8px 0 6px;
          color: #171A20;
          font-size: 14px;
        }
        >em{
          color: #583F16;
          font-size: 10px;
          display: inline-block;
          vertical-align: middle;
          height: 22px;
          line-height: 22px;
          font-style: normal;
          width: 32px;
          margin: 0 6px;
          text-align: center;
        }
      }
      .upgrade-text{
        display: inline-block;
        background-size: 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }
    .order-book-info{
      border-bottom: solid 1px #E6E6E6;
      padding: 24px 0;
    }
    .special-living{
      //height: 70px;
      padding: 20px 0;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      border-bottom: solid 1px #E6E6E6;
      >span{
        font-weight: 700;
        margin-left: 40px;
        -webkit-flex: 1;
        flex: 1;
        text-align: right;
      }
    }
    .order-info{
      padding: 24px 0;
    }
    .total-price{
      text-align: right;
      >label{
        font-size: 16px;
        margin-right: 8px;
      }
      >span{
        font-size: 20px;
        font-weight: 700;
        >i{
          font-size: 12px;
        }
      }
    }
  }
}
