.payment-wrap {
  flex: 1;
  -webkit-flex: 1;
  background-color: #F2F2F2;
}
.payment-wrap .payment-content {
  background-color: #ffffff;
  height: 120px;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.payment-wrap .payment-content > .countdown {
  color: #808285;
  text-align: center;
}
.payment-wrap .payment-content > .value {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #060607;
}
.payment-wrap .payment-content > .value > em {
  font-size: 24px;
}
.payment-wrap .payment-method {
  margin-top: 26px;
}
.payment-wrap .payment-method > span {
  color: #060607;
  font-size: 12px;
  margin: 0 0 10px 16px;
  display: block;
}
.payment-wrap .payment-method > .list {
  background-color: #ffffff;
}
.payment-wrap .payment-method > .list > .item {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #EFEFEF;
  margin-left: 16px;
  height: 60px;
  align-items: center;
  padding-right: 24px;
}
.payment-wrap .payment-method > .list > .item:last-child {
  border: none;
}
.payment-wrap .payment-method > .list > .item label {
  color: #060607;
  font-size: 16px;
  margin-left: 16px;
}
.payment-wrap .payment-method > .list > .item .content {
  -webkt-display: flex;
  display: flex;
  align-items: center;
}
.payment-wrap .payment-method > .list > .item .icon {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  display: block;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.payment-wrap .payment-method > .list > .item .icon.wx {
  background-image: url("../common/images/payment-wx.png");
}
.payment-wrap .payment-method > .list > .item .icon.citic {
  background-image: url("../common/images/payment-citic.png");
}
.payment-wrap .payment-method > .list > .item .icon.nbank {
  background-image: url("../common/images/payment-nbank.png");
}
.payment-wrap .payment-method > .list > .item .selector {
  height: 28px;
  width: 28px;
  border: solid 1px #E6E6E6;
  box-sizing: border-box;
  border-radius: 50%;
}
.payment-wrap .payment-method > .list > .item .selector.selected {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
}
.payment-wrap .payment-action {
  height: 68px;
  background-color: #ffffff;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  -webkit-display: flex;
  display: flex;
}
.payment-wrap .payment-action.disabled > span {
  background: #dcdcdc !important;
}
.payment-wrap .payment-action > span {
  -webkit-flex: 1;
  flex: 1;
  margin: 0 16px;
  border-radius: 100px;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  height: 44px;
  line-height: 44px;
}
