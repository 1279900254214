.hotel-item-container{
  padding: 0 16px 10px 16px;
  border-bottom: solid 1px #E6E6E6;
  margin-bottom: 16px;
  display: block;
  &:last-child{
    margin-bottom: 0;
    border-bottom: none;
  }
  >.base-info{
    -webkit-display: flex;
    display: flex;
    margin-bottom: 8px;
    >.cover{
      width: 100px;
      height: 100px;
      background-repeat: no-repeat;
      background-color: rgba(0, 0, 0, 0.25);
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 4px;
      margin-right: 10px;
    }
    >.msg{
      color: #333;
      position: relative;
      height: 100px;
      -webkit-flex: 1;
      flex: 1;
      padding: 4px 0 8px;
      .hotel-name{
        font-size: 16px;
        height: 18px;
        line-height: 18px;
        font-weight: 700;
        overflow: hidden;
      }
      .hotel-enname{
        font-size: 12px;
        line-height: 14px;
        height: 14px;
        overflow: hidden;
        margin-top: 2px;
      }
      .hotel-tags{
        margin-top: 4px;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 20px;
        overflow: hidden;
        >span{
          width: 28px;
          height: 14px;
          line-height: 14px;
          border: 1px solid rgba(51, 51, 51, 0.45);
          color: rgba(51, 51, 51, 0.65);
          font-size: 10px;
          border-radius: 2px;
          margin-right: 4px;
          padding: 0 4px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
      .price-and-poi{
        position: absolute;
        bottom: 0;
        -webkit-display: flex;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        >.poi{
          position: relative;
          bottom: 2px;
        }
        >.price{
          -webkit-display: flex;
          display: flex;
          flex-direction: column;
          text-align: right;
          >.ori-price{
            color: #858585;
            font-size: 12px;
            >.price-value{
              font-weight: inherit;
            }
          }
          >.price-value{
            color: #FF4F3F;
            font-size: 20px;
            font-weight: 700;
            font-style: normal;
            >.yen{
              font-size: 12px;
              font-weight: normal;
            }
          }
          >.order-now{
            width: 72px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            //background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C); /* Safari 5.1 - 6.0 */
            //background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
            //background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
            //background: linear-gradient(left, #E4CBA3, #C3A87C);
            border-radius: 12px;
            //color: #583F16;
            font-size: 14px;
          }
        }
      }
    }
  }
  >.other-info{
    -webkit-display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .distance{
      font-size: 12px;
      color: #333;
      width: 100px;
      align-self: flex-start;
      height: 14px;
      line-height: 14px;
      position: absolute;
      left: 0;
      top: 0;
    }
    .tags{
      -webkit-flex: 1;
      flex: 1;
      overflow: hidden;
      margin-left: 110px;
      text-align: right;
      margin-bottom: -2px;
      >span{
        //color: #BC8B4C;
        border-radius: 2px;
        border: solid 1px;
        padding: 2px 4px;
        height: 12px;
        line-height: 12px;
        display: inline-block;
        box-sizing: content-box;
        margin-left: 4px;
        margin-bottom: 2px;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
}