.intro-wrap {
  -webkit-flex: 1;
  flex: 1;
  background-color: #222222;
}
.intro-wrap .first-page {
  background-image: url('../common/images/intro_bg.jpg');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  padding: 16px 0;
  -webkit-display: flex;
  display: flex;
  flex-direction: column;
}
.intro-wrap .first-page > .icon-wrap {
  -webkit-display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.intro-wrap .first-page > .icon-wrap > .icon {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../common/images/icon_text2.png");
  width: 160px;
  height: 30px;
}
.intro-wrap .first-page > .icon-wrap > .icon-yongyou {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../common/images/icon-yongyou.png");
  width: 60px;
  height: 30px;
}
.intro-wrap .first-page > .intro {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-image: url("../common/images/intro_main.png");
  padding-top: 45.16%;
  margin: 0 30px;
}
.intro-wrap .first-page .ctrlbtn {
  text-align: center;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
  color: #171A20;
  font-size: 24px;
  height: 56px;
  line-height: 56px;
  margin: 32px 30px 56px;
  border-radius: 2px;
}
.intro-wrap .first-page .intro-text {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  padding: 16px;
  color: #333333;
  font-size: 16px;
  line-height: 32px;
  margin: 20px 32px 0;
  text-align: justify;
  -webkit-flex: 1;
  flex: 1;
  overflow: auto;
  letter-spacing: 1px;
}
.intro-wrap .first-page > .rights {
  margin: 0 24px;
}
.intro-wrap .first-page > .rights > .tab {
  color: #C3A87C;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 50%;
  background-image: url("../common/images/intro_tabbg.png");
  -webkit-display: flex;
  display: flex;
  align-items: center;
}
.intro-wrap .first-page > .rights > .tab > em {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 0 50%;
  background-image: url("../common/images/intro_tabicon.png");
  width: 20px;
  height: 19px;
  margin: 0 6px 0 12px;
}
.intro-wrap .first-page > .rights > .rights-container {
  background-color: #ffffff;
  border-radius: 8px;
  border-top-left-radius: 0;
  padding: 16px 40px 0 20px;
  overflow: hidden;
}
.intro-wrap .first-page > .rights > .rights-container > .item {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: -webkit-linear-gradient(left, #FCE7C6, #E7C49B);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #FCE7C6, #E7C49B);
  background: -moz-linear-gradient(left, #FCE7C6, #E7C49B);
  background: linear-gradient(left, #FCE7C6, #E7C49B);
  position: relative;
  -webkit-display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  float: left;
  margin-right: calc( (100% - 60px - 60px - 60px) / 2 );
  margin-bottom: 40px;
}
.intro-wrap .first-page > .rights > .rights-container > .item:nth-child(3n) {
  margin-right: 0;
}
.intro-wrap .first-page > .rights > .rights-container > .item > span {
  position: absolute;
  top: 0;
  right: 16px;
  background: -webkit-linear-gradient(left, #292F3A, #12151B);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #292F3A, #12151B);
  background: -moz-linear-gradient(left, #292F3A, #12151B);
  background: linear-gradient(left, #292F3A, #12151B);
  color: #D5B480;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  margin-left: -10px;
}
.intro-wrap .first-page > .rights > .rights-container > .item > label {
  color: #000000;
  font-size: 14px;
  margin-top: 48px;
  width: 60px;
  text-align: center;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.intro-wrap .first-page > .intro_arrow {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("../common/images/intro_arrow.png");
  padding-top: 6.9%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-animation: intro_arrowmove 1.5s infinite;
  animation: intro_arrowmove 1.5s infinite;
}
.intro-wrap .rest-page > .imgs {
  overflow: hidden;
}
.intro-wrap .rest-page > .imgs .imgitem {
  width: 93.33%;
  padding-top: 66.29%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-top: 16px;
  float: left;
}
.intro-wrap .rest-page > .imgs .imgitem.imgitem1 {
  background-image: url('../common/images/intro_picture1.jpg');
}
.intro-wrap .rest-page > .imgs .imgitem.imgitem2 {
  background-image: url('../common/images/intro_picture2.jpg');
  float: right;
}
.intro-wrap .rest-page > .imgs .imgitem.imgitem3 {
  background-image: url('../common/images/intro_picture3.jpg');
}
.intro-wrap .rest-page > .brands {
  width: 87.47%;
  padding-top: 80.18%;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('../common/images/intro_brand.png');
  margin: 64px auto 0;
}
.intro-wrap .rest-page > .slogan {
  color: rgba(195, 168, 124, 0.65);
  font-size: 16px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
}
.intro-wrap .rest-page > .ctrlbtn {
  text-align: center;
  background: -webkit-linear-gradient(left, #E4CBA3, #C3A87C);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, #E4CBA3, #C3A87C);
  background: -moz-linear-gradient(left, #E4CBA3, #C3A87C);
  background: linear-gradient(left, #E4CBA3, #C3A87C);
  color: #171A20;
  font-size: 24px;
  height: 50px;
  line-height: 50px;
  margin: 32px 16px;
  border-radius: 2px;
}
.intro-wrap.special-intro-wrap {
  position: relative;
}
.intro-wrap.special-intro-wrap > img {
  display: block;
  width: 100%;
}
.intro-wrap.special-intro-wrap > .entrance {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 85%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-top: 20%;
}
.intro-wrap .bg-wrap-new > img {
  display: block;
  width: 100%;
}
@keyframes intro_arrowmove {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, 6px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@-webkit-keyframes intro_arrowmove {
  /*Safari and Chrome*/
  0% {
    -webkit-transform: translate(-50%, 0);
  }
  50% {
    -webkit-transform: translate(-50%, 6px);
  }
  100% {
    -webkit-transform: translate(-50%, 0);
  }
}
