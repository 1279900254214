.prdlist-wrap {
  flex: 1;
  -webkit-flex: 1;
  background-color: #ffffff;
  position: relative;
}
.prdlist-wrap > img {
  width: 100%;
  display: block;
}
.prdlist-wrap .hotel-wrap {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
}
.prdlist-wrap .hotel-wrap > .hotel-block {
  position: relative;
}
.prdlist-wrap .hotel-wrap > .hotel-block > img {
  width: 100%;
  display: block;
}
.prdlist-wrap .hotel-wrap > .hotel-block > .prds {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.prdlist-wrap .hotel-wrap > .hotel-block > .prds > a {
  flex: 1;
}
