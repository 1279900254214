.rule-wrap {
  flex: 1;
  -webkit-flex: 1;
  padding: 28px 16px;
}
.rule-wrap > .title {
  font-size: 16px;
  color: #333333;
  margin-bottom: 14px;
}
.rule-wrap > .content {
  font-size: 12px;
  color: #333333;
}
