.page-container{
  display: flex;
  -webkit-display: flex;
  flex-direction: column;
  background-color: #fff;
  >header{
    height: 44px;
    line-height: 44px;
    position: relative;
    z-index: 100;
    background-color: #fff;
    display: flex;
    -webkit-display: flex;
    align-items: center;
    padding: 0 18px;
    >span{
      position: relative;
      z-index: 99;
      &.back{
        color: #676767;
        font-size: 16px;
      }
      &.title{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #293031;
        font-size: 18px;
      }
    }
  }
  >section{
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    -webkit-display: flex;
    flex-direction: column;
  }
  >.login-out{
    background-color: #ffffff;
    overflow: hidden;
	  margin-top: -1px;
    height: 0;
    -webkit-display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    -webkit-transition: height 0.25s linear 0.25s;
    transition: height 0.25s linear 0.25s;
    border-bottom: solid 1px rgba(220, 220, 220, 0.35);
    em{
      font-style: normal;
    }
    span{
      background-color: #ffffff;
      border: solid 1px #ff5f12;
      border-radius: 4px;
      color: #ff5f12;
      padding: 6px 10px;
      margin-left: 10px;
    }
    &.display{
      height: 56px;
    }
  }
}

//.theme-primary-color{ color: rgba(240, 0, 0, 1)!important; }
//.theme-primary-bgcolor{ background-color: rgba(240, 0, 0, 1)!important; }
//.theme-primary-bordercolor{ border-color: rgba(240, 0, 0, 1)!important; }
//.theme-primary-btncolor{ background: -webkit-linear-gradient(left, rgba(254, 106, 68, 1), rgba(242, 75, 75, 1))!important; background: linear-gradient(left, rgba(254, 106, 68, 1), rgba(242, 75, 75, 1))!important; color: rgba(255, 255, 255, 1)!important; border: transparent!important; }
//.theme-primary-btncolor-start{ background-color: rgba(254, 106, 68, 1)!important; }
//.theme-primary-btncolor-end{ background-color: rgba(242, 75, 75, 1)!important; }
//.theme-primary-trial{ border-color:rgba(240, 0, 0, 1) transparent transparent!important; }
//
//.theme-secondary-color{ color: rgba(255, 149, 52, 1)!important; }
//.theme-secondary-bordercolor{ border-color: rgba(255, 149, 52, 1)!important; }